<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <OrganizationList/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
import OrganizationList from '@/components/user-dashboard/OrganizationList.vue';

export default {
    components: {
        OrganizationList,
    },
    data: () => ({
        // organization: null,
        error: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
    },
};
</script>
